export function formatPhoneNumber(
  phoneNumber: string,
  previousPhoneNumber: string | null = null,
  cursorPosition: number,
  lastKeyDown: string | null = null
) {
  const BACKSPACE_KEY = 'Backspace';
  const DELETE_KEY = 'Delete';
  const rawNumber = phoneNumber.replace(/\D/g, '');
  let deletionOffset = 0;
  if (
    previousPhoneNumber &&
    previousPhoneNumber.replace(/\D/g, '').length === rawNumber.length
  ) {
    if (lastKeyDown === BACKSPACE_KEY) {
      for (let i = cursorPosition - 1; i >= 0; i--) {
        if (phoneNumber[i].match(/\d/)) {
          phoneNumber = phoneNumber.slice(0, i) + phoneNumber.slice(i + 1);
          deletionOffset += 1;
          break;
        } else {
          deletionOffset += 1;
        }
      }
    } else if (lastKeyDown === DELETE_KEY) {
      for (let i = cursorPosition; i < phoneNumber.length; i++) {
        if (phoneNumber[i].match(/\d/)) {
          phoneNumber = phoneNumber.slice(0, i) + phoneNumber.slice(i + 1);
          deletionOffset -= 1;
          break;
        } else {
          deletionOffset -= 1;
        }
      }
      deletionOffset += 1;
    }
  }

  const updatedRawNumber = phoneNumber.replace(/\D/g, '');

  let adjustedCursor = cursorPosition - deletionOffset;
  const initialAdjustedCursor = adjustedCursor;
  for (let i = 0; i < initialAdjustedCursor; i++) {
    if (!phoneNumber[i].match(/\d/)) {
      adjustedCursor -= 1;
    }
  }

  let formattedPhoneNumber;
  if (updatedRawNumber.length < 3) {
    formattedPhoneNumber = updatedRawNumber;
  } else if (updatedRawNumber.length < 4) {
    formattedPhoneNumber = `(${updatedRawNumber})`;
  } else if (updatedRawNumber.length < 7) {
    formattedPhoneNumber = `(${updatedRawNumber.slice(
      0,
      3
    )}) ${updatedRawNumber.slice(3)}`;
  } else if (updatedRawNumber.length < 8) {
    formattedPhoneNumber = `(${updatedRawNumber.slice(
      0,
      3
    )}) ${updatedRawNumber.slice(3, 6)}-${updatedRawNumber.slice(6)}`;
  } else {
    formattedPhoneNumber = `(${updatedRawNumber.slice(
      0,
      3
    )}) ${updatedRawNumber.slice(3, 6)}-${updatedRawNumber.slice(6)}`;
  }

  let countDigits = 0;
  let newCursorPosition = 0;
  for (const ch of formattedPhoneNumber) {
    if (ch.match(/\d/)) {
      countDigits += 1;
    }
    if (countDigits === adjustedCursor + 1) {
      break;
    }
    newCursorPosition += 1;
  }

  return { formattedPhoneNumber, newCursorPosition };
}
